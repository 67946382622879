












import SendReplyCommentActionModel from "./SendReplyCommentActionModel";
import PostActionMessageSetup from "../components/PostActionMessage/PostActionMessageSetup.vue";

import { Component, VModel, Prop } from 'vue-property-decorator';
import Vue from "vue";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    PostActionMessageSetup,
    ValidationObserver
  }
})
export default class SendReplyCommentActionView extends Vue {
  @VModel() model!: SendReplyCommentActionModel

  @Prop() disabled!: boolean
}
